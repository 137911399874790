<template>
  <!-- 财务管理 -->
  <div>
    <!-- admin -->
    <div v-if="this.shopid === ''" class="reception_num">
      <div class="num_Type flexF">
        <img src="@/assets/img/income.png" alt="">
        <div class="uiRight">
          <p class="un_tit">总收入</p>
          <p class="un_num1">￥{{unitType.income}}</p>
        </div>
      </div>
      <div class="num_Type flexF">
        <img src="@/assets/img/consume.png" alt="">
        <div class="uiRight">
          <p class="un_tit">总消费</p>
          <p class="un_num2">￥{{unitType.umption}}</p>
        </div>
      </div>
      <div class="num_Type flexF">
        <img src="@/assets/img/freeze.png" alt="">
        <div class="uiRight">
          <p class="un_tit">冻结</p>
          <p class="un_num3">￥{{unitType.freeze}}</p>
        </div>
      </div>
      <div class="num_Type flexF">
        <img src="@/assets/img/gross.png" alt="">
        <div class="uiRight">
          <p class="un_tit">总负债</p>
          <p class="un_num4">￥{{unitType.liabilities}}</p>
        </div>
      </div>
    </div>
    <!-- 门店 -->
    <div v-if="this.shopid !== ''" class="reception_num">
      <div class="num_Type flexF">
        <img src="@/assets/img/total.png" alt="">
        <div class="uiRight">
          <p class="un_tit">总收入</p>
          <p class="un_num5">￥{{unitType.income}}</p>
        </div>
      </div>
      <div class="num_Type flexF">
        <img src="@/assets/img/recharge.png" alt="">
        <div class="uiRight">
          <p class="un_tit">总充值</p>
          <p class="un_num4">￥{{unitType.umption}}</p>
        </div>
      </div>
      <div class="num_Type flexF">
        <img src="@/assets/img/results.png" alt="">
        <div class="uiRight">
          <p class="un_tit">总业绩</p>
          <p class="un_num3">￥{{unitType.freeze}}</p>
        </div>
      </div>
      <div class="num_Type flexF">
        <img src="@/assets/img/cash.png" alt="">
        <div class="uiRight">
          <p class="un_tit">现金总额</p>
          <p class="un_num1">￥{{unitType.liabilities}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { finanStatGet } from '@/api/finance.js'
export default {
  data () {
    return {
      unitType: {},
      fianceShow: true,
    }
  },
  created () {
    this.shopid = JSON.parse(window.localStorage.getItem('user')).shopid
    this.init()
  },
  methods: {
    init () {
      finanStatGet().then(res => {
        if (res.status === 1) {
          this.unitType = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
.reception_num {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.num_Type {
  width: calc(25% - 8px);
  background: #fff;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
}
.un_tit {
  font-size: 18px;
  line-height: 25px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #576271;
  margin-left: 5px;
}
.uiRight {
  padding: 10px;
}
.un_num1, .un_num2, .un_num3, .un_num4, .un_num5 {
  line-height: 50px;
  font-size: 38px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
}
.un_num1 {
  color: #FB4B2F;
}
.un_num2 {
  color: #44B557;
}
.un_num3 {
  color: #4A6CDF;
}
.un_num4 {
  color: #F44C8E;
}
.un_num5 {
  color: #3C84EF;
}
</style>

<template>
<!-- 财务管理 -->
  <div class="box100">
    <div class="reception_top">
      <Head></Head>
    </div>
    <div class="reception_fot">
<!--      <template v-if="roleBtn[0].power">-->
<!--        <div class="el-icon-download settingExport"><span> 导出</span></div>-->
<!--      </template>-->
      <div class="flexAC" style="margin: 16px 0px;">
        <div  v-if="this.shopid === ''">
          <el-input v-model="shopid"  placeholder="请输入交易门店编号" class="inputSearch"></el-input>
          <el-select v-model="finantype" placeholder="交易类型" class="retSelect">
            <el-option
              v-for="(item,index) in finantypeList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="sysid" placeholder="支付方式" class="retSelect">
            <el-option v-for="item in payatypeList" :key="item.index" :label="item.sysvalue" :value="item.sysid"></el-option>
          </el-select>
          <el-select v-model="finansource" placeholder="来源" class="retSelect">
            <el-option v-for="item in sourceList" :key="item.index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-date-picker
            v-model="time"
            clearable
            class="utf"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <div v-if="this.shopid !== ''">
          <el-date-picker
            v-model="time"
            clearable
            class="utf"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          height="450"
          :data="finaList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="序号"
            type="index"
            width="100">
          </el-table-column>
          <el-table-column
            label="时间"
            prop="finantime"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="流水"
            prop="finanrun">
            <template slot-scope="scope">
              <span v-if="scope.row.finanrun >= 0"  style="color: #44B557;">{{scope.row.finanrun}}</span>
              <span v-if="scope.row.finanrun < 0"  style="color: #F44C8E;">{{scope.row.finanrun}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="总收入"
            prop="fitearn">
          </el-table-column>
          <!-- <el-table-column
            label="余额"
            prop="fstearn"
            v-if="this.shopid !== ''">
          </el-table-column> -->
          <el-table-column
            label="来源"
            prop="finansource">
            <template slot-scope="scope">
              <span v-if="scope.row.finansource == 1" >订单</span>
              <span v-if="scope.row.finansource == 2" >充值</span>
              <span v-if="scope.row.finansource == 3" >套餐</span>
            </template>
          </el-table-column>
          <el-table-column
            label="交易类型"
            v-if="this.shopid === ''"
            prop="finantype">
            <template slot-scope="scope">
              <span v-if="scope.row.finantype == 0" >前台下单</span>
              <span v-if="scope.row.finantype == 1" >小程序下单</span>
            </template>
          </el-table-column>
          <el-table-column
            label="交易门店"
            prop="shopname"
            v-if="this.shopid === ''"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="订单编号"
            prop="indentid"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="支付方式"
            prop="sysid">
            <template slot-scope="scope">
              <span v-if="scope.row.sysid == 1" >微信支付</span>
              <span v-if="scope.row.sysid == 2" >支付宝支付</span>
              <span v-if="scope.row.sysid == 3" >余额支付</span>
              <span v-if="scope.row.sysid == 4" >银行卡支付</span>
              <span v-if="scope.row.sysid == 5" >其他支付</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Head from '@/components/finance/head'
import { finanGet } from '@/api/finance.js'
import { sysGet } from '@/api/system.js'
export default {
  components: {
    Head
  },
  props: ['roleBtn'],
  data () {
    return {
      shopid: '',
      page: 1,
      size: 10,
      total: 0,
      finaList: [],
      keyWord: '',
      keyStore: '',
      storeList: [],
      time: null,
      finantype: '',
      finantypeList: [
        { label: '平台交易', value: 1 }, { label: '线下交易', value: 2 }
      ],
      sysid: '',
      payatypeList: [],
      finansource: '',
      sourceList: [{ label: '订单', value: 1 }]
    }
  },
  created () {
    this.shopid = JSON.parse(window.localStorage.getItem('user')).shopid
    this.init()
    if (this.shopid === '') {
      this.getInit()
    }
  },
  methods: {
    init () {
      let staTime, endTime = ''
      if (this.time != null) {
        staTime = this.time[0]
        endTime = this.time[1]
      }
      const params = {
        shopid: this.shopid,
        sysid: this.sysid,
        finansource: this.finansource,
        finantype: this.finantype,
        faultstime: staTime,
        faultetime: endTime,
        page_index: this.page,
        page_size: this.size
      }
      finanGet(params).then(res => {
        if (res.status === 1) {
          this.finaList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.finaList = []
          this.total = 0
        }
      })
    },
    getInit () {
      // 支付方式
      sysGet({ systype: 'payway' }).then(res => {
        if (res.status === 1) {
          this.payatypeList = res.data.data
        } else {
          this.payatypeList = []
        }
      })
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.finantype = ''
      this.sysid = ''
      this.finansource = ''
      this.time = []
      this.init()
    },
    pageChange (page) {
      this.page = page
      this.init()
    }
  }
}
</script>

<style scoped>
.reception_top{
  width: 100%;
  height: 160px;
}
.reception_fot{
  margin-top: 8px;
  width: 100%;
  height: calc(100% - 160px);
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
}
.table_list >>>.el-table__body-wrapper::-webkit-scrollbar {
	width: 0;
}
.retSelect {
  width: 140px;
}
.utf {
  height: 32px;
  padding: 0 12px;
  box-sizing: border-box;
}
</style>
